@use 'colors.scss' as *;

.footer {
	width: 100%;

	display: flex;
	flex-direction: row;

	gap: 20px;

	justify-content: center;
	align-items: center;

	padding-block: 10px;

	background: $background;

	position: fixed;

	bottom: 0;
	left: 0;

	.footer-text {
		color: $light-background;
		font-size: 16px;
	}

	.footer-button {
		cursor: pointer;
	}

	.footer-links {
		* {
			color: $light-background;
		}
	}
}
