@use './colors.scss' as *;

* {
	padding: 0;
	margin: 0;
}

body {
	background: $background;

	font-family: sans-serif;
	font-size: 15pt;

	color: $light;
}

a {
	text-decoration: none;
	color: $light;
}

h4 {
	font-weight: normal;
}
