@use 'colors.scss' as *;
@use 'vars.scss' as *;

.loading-games {
	position: absolute;

	left: 50%;
	top: 50%;

	transform: translate(-50%, -50%);
}
.home {
	display: flex;
	flex-direction: column;

	align-items: center;

	gap: 30px;

	padding-bottom: 50px;

	.title-container {
		display: flex;
		flex-direction: column;

		align-items: center;

		gap: 10px;

		color: $medium;

		.title {
			display: flex;
			flex-direction: row;

			align-items: center;

			color: $light;

			.title-primary {
				color: $primary;
			}
		}
	}

	.changelog-container {
		display: flex;
		flex-direction: column;

		align-items: center;

		gap: 10px;

		.newest-game-title {
			color: $medium;
			cursor: pointer;
		}

		span {
			color: $light-background;
			font-weight: lighter;
		}
	}

	.seperator-line {
		height: 1px;
		width: 60%;

		background: $light-background;
	}

	.games {
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		gap: 20px;

		padding-inline: 10%;

		.empty-games {
			height: 100%;

			display: flex;
			flex-direction: column;

			align-items: center;

			.empty-games-title {
				color: $medium;
			}
		}

		.rate-game {
			border-radius: 10px;
			background: $background;

			padding: 20px;

			max-width: 300px;

			display: flex;
			flex-direction: column;

			align-items: center;
			justify-content: space-between;

			gap: 20px;

			border: 1px solid $light-background;

			transition: $transition;

			position: relative;

			.rating-selector {
				position: absolute;

				left: 50%;
				top: 50%;

				transform: translate(-50%, -50%);
			}
		}

		.game {
			border-radius: 10px;
			background: $background;

			padding: 10px;

			max-width: 300px;

			display: flex;
			flex-direction: column;

			gap: 20px;

			border: 1px solid $light-background;

			transition: $transition;

			position: relative;

			.top-tag {
				position: absolute;

				left: 0;
				top: 0;

				background: $secondary;

				width: 40px;
				height: 40px;

				transform: translate(-20%, -20%);

				display: flex;

				align-items: center;
				justify-content: center;

				z-index: 1;

				text-align: center;
			}

			.top-tag:before,
			.top-tag:after {
				content: '';

				position: absolute;

				top: 0;
				left: 0;

				width: 100%;
				height: 100%;

				background: inherit;
				z-index: -1;

				transform: rotate(30deg);
			}

			.top-tag:after {
				transform: rotate(-30deg);
				z-index: -1;
			}

			.game-title-container {
				display: flex;
				flex-direction: row;

				align-items: center;

				gap: 10px;

				.game-stars {
					display: flex;
					flex-direction: row;

					align-items: center;

					.star-solid {
						color: $primary;
					}

					.star-gold {
						color: $gold;
					}

					.star-regular {
						color: $light-background;
					}
				}
			}

			.game-image {
				width: 100%;
				height: 300px;

				border-radius: 5px;

				object-fit: cover;
			}

			.game-info {
				display: flex;
				flex-direction: column;

				gap: 10px;

				margin-bottom: 50px;

				span {
					color: $medium;
					font-weight: lighter;
				}
			}

			.game-buttons {
				display: flex;
				flex-direction: row;

				gap: 10px;

				bottom: 0;

				width: calc(100% - 20px);

				position: absolute;

				padding-block: 10px;

				.button {
					width: 100%;
				}

				a {
					width: 100%;
				}
			}
		}

		.game:hover {
			border: 1px solid $primary;
		}
	}

	.games-empty {
		display: flex;
		flex-direction: row;

		align-items: center;
		justify-content: center;
	}
}
