@use 'colors.scss' as *;
@use 'vars.scss' as *;

.submit-broken-game-container {
	backdrop-filter: blur(30px);

	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	z-index: 10;

	animation-name: fade-in;

	animation-duration: 0.5s;
	animation-fill-mode: forwards;

	.submit-broken-game-content {
		position: absolute;

		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);

		display: flex;
		flex-direction: row;

		gap: 20px;

		padding: 20px;

		border: 1px solid $light-background;
		border-radius: 10px;

		background: $background;

		z-index: 2;

		height: 300px;

		.submit-broken-game {
			display: flex;
			flex-direction: column;

			justify-content: space-around;

			gap: 10px;

			.submit-broken-game-buttons {
				display: flex;
				flex-direction: column;

				gap: 10px;
			}
		}
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
