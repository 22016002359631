@use 'colors.scss' as *;

.navbar {
	top: 0;
	left: 0;

	width: calc(100% - 20px);
	height: 50px;

	padding: 10px;

	display: flex;
	flex-direction: row;

	justify-content: space-between;

	position: fixed;

	background: $background;

	left: 0;
	top: 0;

	z-index: 9;

	border-bottom: 1px solid rgba($light-background, 0);

	.broken-game-button {
		cursor: pointer;

		color: $light-background;
	}

	.navbar-section {
		display: flex;
		flex-direction: row;

		gap: 20px;

		align-items: center;

		.navbar-title {
			display: flex;
			flex-direction: row;

			gap: 10px;

			align-items: center;

			cursor: pointer;

			.logo {
				height: 20pt;
			}

			.title-primary {
				color: $primary;
			}
		}

		.navbar-button {
			cursor: pointer;
		}
	}
}
