@use 'colors.scss' as *;
@use 'vars.scss' as *;

.star-selector {
	display: flex;
	flex-direction: column;

	align-items: center;

	gap: 5px;

	.star-selector-header {
		display: flex;
		flex-direction: row;

		align-items: center;

		gap: 10px;

		position: relative;
	}

	.stars-container {
		display: flex;
		flex-direction: row;

		align-items: center;

		gap: 15px;

		.star {
			cursor: pointer;
		}

		.label {
			color: $dark;

			font-size: 10pt;
		}

		.star-solid {
			color: $primary;
		}

		.star-gold {
			color: $gold;
		}

		.star-regular {
			color: $light-background;
		}
	}
}
