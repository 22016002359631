@use 'colors.scss' as *;
@use 'vars.scss' as *;

.text-area {
	background: $dark;

	padding: 10px;

	width: 400px;
	height: 100%;

	outline: none;
	border: 1px solid $light-background;

	color: $light;
}
