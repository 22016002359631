@use 'colors.scss' as *;
@use 'vars.scss' as *;

.info-container {
	backdrop-filter: blur(30px);

	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	z-index: 10;

	animation-name: fade-in;

	animation-duration: 0.5s;
	animation-fill-mode: forwards;

	.info {
		position: absolute;

		top: 50%;
		left: 50%;

		width: 40%;

		transform: translate(-50%, -50%);

		padding: 20px;

		border: 1px solid $light-background;
		border-radius: 10px;

		background: $background;

		z-index: 2;

		display: flex;
		flex-direction: column;

		gap: 20px;

		.section-title {
			color: $primary;

			text-align: center;
		}

		.analytics-button {
			cursor: pointer;

			text-align: center;
		}

		.list {
			margin-left: 20px;

			span {
				cursor: pointer;
			}
		}
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
