@use 'colors.scss' as *;
@use 'vars.scss' as *;

.button {
	border: 1px solid $primary;
	border-radius: 5px;

	padding: 10px 20px;

	cursor: pointer;

	background: $light;

	transition: $transition;
}

.button-disabled {
	pointer-events: none;

	background: transparent;
	color: $light-background;

	border-color: $light-background;
}

.button-disabled-invert {
	pointer-events: none;

	background: transparent;
	color: $background;

	border-color: $background;
}

.button-big {
	height: 50px;
}

.button-cta {
	background: $cta;
	color: $light;

	border-color: $dark;
}

.button-icon {
	display: flex;
	flex-direction: row;

	align-items: center;
	justify-content: center;

	gap: 10px;
}

.button-transparent {
	background: transparent;
	color: $light;

	border-color: $light;
}

.button:hover {
	background: rgba($light, 0.8);
	color: $dark;
}

.button:active {
	background: $primary;
	color: $light;

	border-color: $dark;
}

.button-danger {
	background: $primary;
	color: $light;
}

.button-danger:hover {
	background: $light;
	color: $dark;
}

.button-danger:active {
	background: rgba($primary, 0.6);
	color: $light;
}
