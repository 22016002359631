$background: #1f1f26;
$light-background: #3f3f4e;
$primary: #d61f4d;
$secondary: #ffa42e;
$gold: #ffe06e;
$cta: #218cff;
$light: #ffffff;
$medium: #a0a0a0;
$dark: #000000;
$success: #85ffac;
