@use 'colors.scss' as *;
@use 'vars.scss' as *;

.game-request-container {
	backdrop-filter: blur(30px);

	position: fixed;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	z-index: 10;

	animation-name: fade-in;

	animation-duration: 0.5s;
	animation-fill-mode: forwards;

	.game-request-content {
		position: absolute;

		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);

		display: flex;
		flex-direction: row;

		gap: 20px;

		padding: 20px;

		border: 1px solid $light-background;
		border-radius: 10px;

		background: $background;

		z-index: 2;

		.games-todo {
			display: flex;
			flex-direction: column;

			gap: 10px;
			max-height: 400px;

			.games-todo-list {
				display: flex;
				flex-direction: column;

				gap: 10px;

				list-style-type: disc;

				overflow-y: scroll;

				.game-status-in-progress {
					color: $secondary;
				}

				.game-status-todo {
					color: $medium;
				}

				.game-status-finished {
					color: $success;
				}

				li {
					margin-left: 20px;
				}
			}
		}

		.game-request {
			display: flex;
			flex-direction: column;

			justify-content: space-around;

			gap: 10px;

			.game-request-buttons {
				display: flex;
				flex-direction: column;

				gap: 10px;
			}
		}
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
