.chart-container {
	padding: 20px;

	width: 100%;
	height: 100%;

	.chart {
		width: 100%;
		height: 100%;
	}
}
