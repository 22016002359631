@use 'colors.scss' as *;

.game-ad-spot {
	width: 12.5%;
}

.game-container {
	width: calc(100%);
	height: calc(100vh - 263px);

	margin-bottom: 140px;

	z-index: 10;

	.game-frame {
		position: absolute;

		left: 50%;

		transform: translateX(-50%);

		width: 80%;
		height: calc(100% - 70px);

		border: none;
		outline: none;
	}

	.loader-label {
		text-align: center;

		width: 100%;

		padding: 20px;

		opacity: 1;

		z-index: 5;

		display: block;
	}

	.credit-label {
		text-align: center;

		width: 100%;

		padding: 20px;

		opacity: 1;

		color: $medium;

		z-index: 5;
	}

	.loader {
		width: 50px;
		aspect-ratio: 1;

		--c: no-repeat linear-gradient(white 0 0);

		background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
		background-size: 20% 100%;

		animation: load 1s infinite linear;

		position: absolute;

		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);
	}

	@keyframes load {
		0% {
			background-size: 20% 100%, 20% 100%, 20% 100%;
		}

		33% {
			background-size: 20% 10%, 20% 100%, 20% 100%;
		}

		50% {
			background-size: 20% 100%, 20% 10%, 20% 100%;
		}

		66% {
			background-size: 20% 100%, 20% 100%, 20% 10%;
		}

		100% {
			background-size: 20% 100%, 20% 100%, 20% 100%;
		}
	}

	.game-frame-fullscreen {
		width: 100%;
		height: 100%;

		position: static;
	}

	.fullscreen-icon {
		position: absolute;

		left: 10%;

		transform: translate(50%, 50%);

		margin: 10px;

		z-index: 1;

		cursor: pointer;
	}
}