.privacy-policy {
	display: flex;
	flex-direction: column;

	text-align: center;

	align-items: center;
	justify-content: center;

	gap: 20px;
}
