@use 'colors.scss' as *;
@use 'vars.scss' as *;

.takedown {
	position: fixed;

	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);

	display: flex;
	flex-direction: column;

	gap: 20px;

	.qa {
		display: flex;
		flex-direction: column;

		gap: 20px;
	}
}

.cloak-screen {
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	border: none;
	outline: none;

	z-index: 999999;
}

.uncloak-button {
	position: absolute;

	bottom: 10px;
	left: 10px;

	z-index: 9999991;
}

.content {
	margin-top: 100px;
	padding-bottom: 100px;
}

.ad-spot {
	position: fixed;

	height: calc(100% - 140px);
	width: 15%;

	// background: rgba($light-background, 0.2);
	background: transparent;

	z-index: 2;
}

.ad-spot-left {
	left: 0;
}

.ad-spot-right {
	right: 0;
}